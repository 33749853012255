.page-header{
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .w100{
        width: 100%;
    }
    .title{
        font-size: 24px;
        font-weight: 500;
    }
}
