.product-detail {
    &__images {
        img {
            width: 80px;
            height: 80px;
        }
    }

    &__label {
        text-align: right;
        padding-right: 130px;
    }

    .plus-icon {
        cursor: pointer;
        
    }
}